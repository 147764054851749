<template>
  <div style="text-align: left; margin: 15px;" :class="[{'hidden':!question.display}]">
    <h5>
        ({{questionIndex+1}})
        <span v-html="question.body.text[language]"/> - [id: {{question.id}}]
    </h5>
    <template v-for="group in question.groups">
        <div class="question-group" :class="[{'notRequired':!group.requirements.required}]" :key="`question-${question.id}-group-${group.id}`">
            <b v-html="group.text[language]"/> <sub>- {{group.type}} | required: {{group.requirements.required ? 'true' : 'false'}}</sub>

            <ol class='question-group-choices' v-if="group.type!='number'">
                <template v-for="choice in group.choices">
                    <li v-if="choice.text[language] && choice.text[language].length>0" :key="`question-${question.id}-group-${group.id}-choice-${choice.id}`" :class="{'selected':answer && answer.group[group.id] && ( group.type=='checkbox' && answer.group[group.id].value.includes(choice.value) || (answer.group[group.id].value == choice.value))}">
                        <span v-html="choice.text[language]"/>
                        <span v-if="answer && answer.group && answer.group[group.id] && answer.group[group.id].others && answer.group[group.id].others[choice.id]"> ==> {{answer.group[group.id].others[choice.id]}}</span>
                    </li>
                </template>
            </ol>

            <ul class='question-group-choices' v-else-if="group.type=='number'">
                <template v-for="choice in group.choices">
                    <li :key="`question-${question.id}-group-${group.id}-choice-${choice.id}`" :class="{'selected':answer && answer.group[group.id] && ( group.type=='checkbox' && answer.group[group.id].value.includes(choice.value) || (answer.group[group.id].value == choice.value))}">
                        <span v-html="choice.text[language]"/>
                        <span class='selected' v-if="answer && answer.group && answer.group[group.id].value && answer.group[group.id].value[choice.id]"> ==> {{answer.group[group.id].value[choice.id]}}</span>
                        <span v-if="answer && answer.group && answer.group[group.id] && answer.group[group.id].others && answer.group[group.id].others[choice.id]"> ==> {{answer.group[group.id].others[choice.id]}}</span>
                    </li>
                </template>
            </ul>

        </div>
    </template>
  </div>
</template>

<script>
export default {
    props: {
        parentKey: {
            type: String,
            required: false,
            default: ''
        },
        questionIndex: {
            type: Number,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        answers: {
            type: Object,
            required: false
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        answer: function(){
            let answers = this.answers
            let question = this.question
            return (answers && answers[question.id]) ? answers[question.id] : null
        }
    }
}
</script>

<style scoped lang="scss">
.notRequired{
    font-style: italic;
}

.hidden{
    color: grey;
}

.question-group li{
    font-size: 0.8em;
}

.selected{
    font-weight: bold;
    color: green;
}
li.selected{
    background-color: whitesmoke;
}
</style>
