<template>
  <div>

      <div class="cont_filter">
          <div class="wrapper">
              <v-card class='flex'>
                  <v-container fluid>
                      <v-row align="center">
                          <v-col cols="12" sm="12">
                              <v-select
                              v-model="filter_versions.qs_1"
                              :items="filter_items"
                              chips
                              label="Filter Question Set 1 Versions"
                              multiple
                              ></v-select>
                          </v-col>
                      </v-row>
                  </v-container>
                  <v-btn class="themed" @click="createDownload_surveyResultsCSV(1)">Download Survey Results <br>CSV – Visit 1</v-btn>
              </v-card>
              <br>
              <v-card class='flex'>
                  <v-container fluid>
                      <v-row align="center">
                          <v-col cols="12" sm="12">
                              <v-select
                              v-model="filter_versions.qs_2"
                              :items="filter_items"
                              chips
                              label="Filter Question Set 2 Versions"
                              multiple
                              ></v-select>
                          </v-col>
                      </v-row>
                  </v-container>
                  <v-btn class="themed" @click="createDownload_surveyResultsCSV(2)">Download Survey Results <br>CSV – Visit 2</v-btn>
              </v-card>
              <br>
              <v-card class='flex'>
                  <v-container fluid>
                      <v-row align="center">
                          <v-col cols="12" sm="12">
                              <v-select
                              v-model="filter_versions.qs_3"
                              :items="filter_items"
                              chips
                              label="Filter Question Set 3 Versions"
                              multiple
                              ></v-select>
                          </v-col>
                      </v-row>
                  </v-container>
                  <v-btn class="themed" @click="createDownload_surveyResultsCSV(3)">Download Survey Results <br>CSV – Visit 3</v-btn>
              </v-card>
          </div>
      </div>

    <v-btn class="themed" @click="download">Refresh</v-btn>

    <v-container v-if="!states.downloading">
        <v-row>
            <v-col>
                <h4>Visit #1</h4>
                <Question v-for="(question, questionIndex) in questionSets.first_visit" :key="`first_visit_${question.id}`" :parentKey="'first_visit'" :questionIndex="questionIndex" :question="question" :answers="selection.first_visit ? selection.first_visit.data.answers.questions : null"/>
            </v-col>
            <v-col>
                <h4>Visit #2</h4>
                <Question v-for="(question, questionIndex) in questionSets.second_visit" :key="`second_visit_${question.id}`" :parentKey="'second_visit'" :questionIndex="questionIndex" :question="question" :answers="selection.second_visit ? selection.second_visit.data.answers.questions : null"/>
            </v-col>
            <v-col>
                <h4>Visit #3</h4>
                <Question v-for="(question, questionIndex) in questionSets.third_visit" :key="`third_visit_${question.id}`" :parentKey="'third_visit'" :questionIndex="questionIndex" :question="question" :answers="selection.third_visit ? selection.third_visit.data.answers.questions : null"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h4>Visit #1</h4>
                <SubmissionSelector @select="selectSubmission" :parentKey="'first_visit'" :selection="selection.first_visit" :submissions="submissions.first_visit"/>
            </v-col>
            <v-col>
                <h4>Visit #2</h4>
                <SubmissionSelector @select="selectSubmission" :parentKey="'second_visit'" :selection="selection.second_visit" :submissions="submissions.second_visit"/>
            </v-col>
            <v-col>
                <h4>Visit #3</h4>
                <SubmissionSelector @select="selectSubmission" :parentKey="'third_visit'" :selection="selection.third_visit" :submissions="submissions.third_visit"/>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import Question from '@/components/Questions/dataDump/question.vue'
import SubmissionSelector from '@/components/Questions/dataDump/submissionSelector.vue'
export default {
    components: {
        Question,
        SubmissionSelector
    },
    data: function(){
        return {
            filter_items: [1, 2, 3],
            filter_versions: {
                qs_1: [1, 2, 3],
                qs_2: [1, 2, 3],
                qs_3: [1, 2, 3]
            },
            questions: [],
            questionAnswers: [],
            questionSets: {
                first_visit: [],
                second_visit: [],
                third_visit: []
            },
            selection: {
                first_visit: null,
                second_visit: null,
                third_visit: null
            },
            states: {
                downloading: false
            },
            submissions: {
                first_visit: [],
                second_visit: [],
                third_visit: []
            },
            table_surveyResults: {
                qs_1: [],
                qs_2: [],
                qs_3: []
            },
            users: []
        }
    },
    created: async function(){
        this.download()
        await this.pass_questions_questionAnswers_users_toData();
        this.set_tableSurveyResults();
    },
    methods: {
        create_csvString_from2dArray(table){
            return new Promise((resolve, reject)=>{

                // let csvString = 'data:text/csv;charset=utf-8,'; // start string with csv file header declarations
                let csvString = 'data:text/csv;charset=utf-8,\uFEFF'; // start string with csv file header declarations (the '\uFEFF' makes a difference. Displays things like ≤ properly)

                if( table === null || table === undefined || !table.length){
                    console.log('ERROR IN create_csvString_from2dArray(table): table argument expects an array, but found null, undefined, a number, an object, or has no length.')
                    resolve(false);
                    return;
                };

                for(let r = 0; r < table.length; r++){ // traverse rows of the table
                    let row = table[r];

                    if( !row.length              // row array has no length
                    &&  r === table.length - 1){ // AND End of table traversal
                        resolve(csvString);
                    }
                    else
                    if( !row.length){ // row array has no length
                        // SET: newline onto csvString
                        csvString += '\n';
                    }
                    else{
                        for(let c = 0; c < row.length; c++){ // traverse cols of the row
                            let col = row[c];

                            // MODIFY: col into properly formated colString
                            let colString = `${col}`; // Ensure working with a string
                                colString = colString.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' '); // Clean text to remove multiple spaces and jumpline (which breaks csv)
                                // colString = colString.replace(/"/g, '""'); // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                            // MODIFY: colString into uriEncodedString
                            let uriEncodedString = encodeURI(colString);                    // native URI encoding method
                                uriEncodedString = uriEncodedString.replaceAll('#', '%23'); // hashmarks replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("'", '%27'); // single quotes replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("_", '%5f'); // underscores replaced (breaks csv)

                            // CREATE: colStringWithSeparator
                            let colSeparator = ',';
                            let colStringWithSeparator = `${colSeparator}${uriEncodedString}`

                            // SET: colStringWithSeparator onto csvString
                            csvString += colStringWithSeparator;

                            if( r === table.length - 1 // End of table traversal
                            &&  c === row.length - 1){ // AND End of row traversal
                                resolve(csvString);
                            }
                            else
                            if( c === row.length - 1){ // End of row traversal
                                // SET: newline onto csvString
                                csvString += '\n';
                            };
                        };
                    };
                };
            });
        },
        createDownload_surveyResultsCSV: async function(id_questionSetId){
            let CSVString = await this.create_csvString_from2dArray(this.table_surveyResults[`qs_${id_questionSetId}`])
            if( CSVString === false){
                return;
            };
            this.download_CSVFile_fromCSVString(CSVString, `CANTarget_SurveyResults_Visit_${id_questionSetId}_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`);
        },
        download: async function(){
            this.states.downloading = true
            this.selection.first_visit = null
            this.selection.second_visit = null
            let first_visitRequest = await this.sendRequest('get',`/api/questionSet/getQuestions/1`)
            let second_visitRequest = await this.sendRequest('get',`/api/questionSet/getQuestions/2`)
            let third_visitRequest = await this.sendRequest('get',`/api/questionSet/getQuestions/3`)
            this.questionSets.first_visit = first_visitRequest.data.questions
            this.questionSets.second_visit = second_visitRequest.data.questions
            this.questionSets.third_visit = third_visitRequest.data.questions

            let submissionRequest = await this.sendRequest('get',`/api/questionSet/admin/dataDump`)
            let submissions = submissionRequest.data
            this.submissions.first_visit = []
            this.submissions.second_visit = []
            this.submissions.third_visit = []

            for(let i=0; i<submissions.length; i++){
                let submission = submissions[i]
                this.submissions[submission.questionSetId==1 ? 'first_visit' : submission.questionSetId==2 ? 'second_visit' : 'third_visit'].push(submission)
            }
            this.states.downloading = false
        },
        download_CSVFile_fromCSVString: function(CSVString, filename = 'filename'){
            // CREATE: link HTML
            let link = `<a id='csvLink' href='${CSVString}' download='${filename}.csv'>`;
            // GIVE: link HTML to body
            document.body.insertAdjacentHTML('afterend', link);
            // GET: link on body
            let csvLink = document.querySelector('#csvLink');
            // CLICK: link to download
            csvLink.click();
            // REMOVE: link element
            csvLink.remove();
        },
        pass_questions_questionAnswers_users_toData: async function(){
            return new Promise(async(resolve, reject)=>{
                let url = '/api/dashboardMetrics/1';
                this.sendRequest('GET', url).then(async(response)=>{
                    // console.log(response.data, 'DATA from surveyResults route');

                    this.questions = response.data.questions;
                    this.questionAnswers = response.data.questionAnswers;
                    this.users = response.data.users;
                    // console.log(`questions`, JSON.parse(JSON.stringify(this.questions)));
                    // console.log(`questionAnswers`, JSON.parse(JSON.stringify(this.questionAnswers)));
                    // console.log(`users`, JSON.parse(JSON.stringify(this.users)));

                    resolve();
                });
            });
        },
        selectSubmission: function(submission){
            console.log('selectSubmission', submission)
            this.selection[submission.questionSetId==1 ? 'first_visit' : submission.questionSetId==2 ? 'second_visit' : 'third_visit'] = submission
        },
        set_tableSurveyResults: async function(){

            // Break references to data we want to work with
            let data_questions = JSON.parse(JSON.stringify(this.questions));
            let data_questionAnswers = JSON.parse(JSON.stringify(this.questionAnswers));
            let data_users = JSON.parse(JSON.stringify(this.users));

            // Data Stores for questionSets. Going to split them up to work with them
            let questionSets = {
                qs_1: [],
                qs_2: [],
                qs_3: []
            };
            // Flag to control when to build the header row
            let is_createHeader = {
                qs_1: false,
                qs_2: false,
                qs_3: false
            };

            /*
            PROBLEM:
            - The CSV output prints all questions and choices for a questionSet.
            - I collect all questions for a questionSet into their own arrays, excluding duplicates.
            - But I noticed questions can change between questionSets without changing the ID (ex: qs_1 v_3 qid_3 has 'other' in addition to 'male' and 'female').
            - This is a problem because we could potentially miss printing a question choice options if ar exluding questions we think are the same, but had extra choice options added to it.
            - Changed questions should get a new question id. This is not the case.
            - To lessen the problem, I am always talking the question from the latest version.
            - Still, if a newer version drops a choice option from a prior version, you will lose that choice option and answert tally for it.
            - A perfect solition to this problem is either giving changed questions new ids,
            OR, modifying the code that adds questions to respective questionsSets while exlcuding what was thought to be duplicate questions,
            check and compare choice options for seemingly duplicate questions and taking all unique choice options.

            The following function needs to fire before set_questionSets_fromQuestions(),
            because that function adds all questions -- exluding duplicates -- from different questionSet versions to one questionSet array.
            I want the questionSet versions in descending order if I am forward-looping the questions array and adding to questionSets arrays.
            That way I am always taking a question from the latest questionSet version over a seemingly duplicate one from a prior questionSet version.
            */
            modify_dataQuestions_orderByVersionDescending(data_questions);

            data_questions = await remove_versionsNotSelected_fromDataQuestions(data_questions, this.filter_versions);
            data_questionAnswers = await remove_versionsNotSelected_fromQuestionAnswers(data_questionAnswers, this.filter_versions);

            set_questionSets_fromQuestions(data_questions);

            modify_questionSets_orderById(questionSets, this.filter_versions);
            // console.log(`questionSets`, questionSets);



            // CREATE TABLE FOR CSV

            for(let i = 0; i < data_questionAnswers.length; i++){

                let obj_submission   = data_questionAnswers[i];
                let id_questionSet   = obj_submission.questionSetId;
                let version          = obj_submission.version;
                let id_user          = obj_submission.userId;
                let id_patient       = obj_submission.data.patient.patient_id;
                let visitType        = obj_submission.data.visitType;
                let date_visit_01    = obj_submission.data.patient.first_visit;
                let date_visit_02    = obj_submission.data.patient.second_visit;
                let date_visit_03    = obj_submission.data.patient.third_visit;
                let obj_answers      = obj_submission.data.answers.questions;

                let userDetails = await retrieve_userDetails(data_users, id_user);

                if( is_createHeader[`qs_${id_questionSet}`] === false){
                    var header = ['User Id', 'Given Name', 'Family Name', 'User Email', 'User Role', 'Patient Id', 'Visit Type', 'Date Visit 1', 'Date Visit 2', 'Date Visit 3', 'Question Set Id', 'Version'];
                };
                let row = [id_user, userDetails.name_given, userDetails.name_family, userDetails.email, userDetails.role, id_patient, visitType, date_visit_01, date_visit_02, date_visit_03, id_questionSet, version];

                // CHANGE TO LOOPING questionSets
                let array_questions = questionSets[`qs_${id_questionSet}`];

                for(let ii = 0; ii < array_questions.length; ii++){

                    let obj_question  = array_questions[ii];
                    let id_question   = obj_question.id;
                    let text_question = obj_question.body.text['en-ca'];
                    let array_groups  = obj_question.groups;

                    /**********************************************
                    SPECIAL CASE for QuestionSet 1, Question Id 2:
                    This question asked about province.
                    It was only included in QuestionSet 1, Question Id 2.
                    It has no responses on it, and there is a want to remove it from the data dump.
                    ***********************************************/
                    if( id_questionSet == 1 && id_question == 2){
                        continue;
                    };
                    /**********************************************/

                    if( is_createHeader[`qs_${id_questionSet}`] === false){
                        let questionTextAndId = `${text_question} [Qid: ${id_question}]`;
                        header.push(questionTextAndId);
                    };
                    row.push('');

                    for(let iii = 0; iii < array_groups.length; iii++){

                        let obj_group     = array_groups[iii];
                        let id_group      = obj_group.id;
                        let type_group    = obj_group.type;
                        let array_choices = obj_group.choices;

                        if( is_createHeader[`qs_${id_questionSet}`] === false){
                            header.push(`Group [Id: ${id_group}] - Type: ${type_group}`);
                        };
                        row.push('');

                        for(let iv = 0; iv < array_choices.length; iv++){

                            let obj_choice   = array_choices[iv];
                            let value_choice = obj_choice.value;
                            if( value_choice == null){ // nothing found for choice, look for text
                                value_choice = obj_choice.text['en-ca'];
                            };
                            let otherOption  = obj_choice.other;

                            if( is_createHeader[`qs_${id_questionSet}`] === false){
                                let text_choice = `"${obj_choice.text['en-ca']}"`; // wrapped in double quotes so any commas don't break CSV
                                header.push(text_choice);

                                // if( otherOption === true){
                                //     let other_text = `"${obj_choice.other_text['en-ca']}"`;
                                //     header.push(other_text);
                                // };
                            };

                            // NOW, GO BACK TO LOOKING AT questionAnswer TO SEE IF FIND MATCH
                            // obj_answers from questionAnswers, id_question onwards from questionSets

                            let find_answer = null;
                            let mark = '';

                            if( obj_answers[id_question] !== undefined // question from questionSet was answered in questionAnswers
                            &&  value_choice !== null){                // choice(option) in questionSets isn't null (can be null for text answers)

                                find_answer = obj_answers[id_question].group[id_group].value;
                                // console.log(`find_answer: ${id_questionSet}––${version}––${id_question}`, find_answer);

                                /*********************************************
                                SPECIAL CASE for QuestionSet 1, Question 15:
                                It's a radio between value 'confirmed' and 'not_confirmed'.
                                Not_confirmed is marked as null, when want it to be 'not_confirmed'.
                                This doesn't mark 1 in the not_confirmed column.
                                So will find this case and change null to not_confirmed
                                **********************************************/
                                if( id_questionSet == 1 && id_question == 15){
                                    if( find_answer == null){
                                        find_answer = 'not_confirmed';
                                    };
                                };
                                /*********************************************/

                                if( otherOption === true){
                                    let obj_otherOpenResponses = obj_answers[id_question].group[id_group].others;

                                    let v = 0;
                                    for(let key_otherOpenResponses in obj_otherOpenResponses){
                                        let openResponse = obj_otherOpenResponses[key_otherOpenResponses];

                                        mark += ` ${openResponse}`;

                                        v++;
                                        if( v === Object.keys(obj_otherOpenResponses).length){ //end of loop
                                            mark = `"${mark}"`; // wrapped in double quotes so any commas don't break CSV
                                        };
                                    };
                                }
                                // STRING (or number as string)
                                else
                                if( typeof(find_answer) == 'string'){

                                    if( value_choice == find_answer){

                                        mark = 1;
                                    }
                                }
                                // NULL or UNDEFINED
                                else
                                if( typeof(find_answer) == 'object'
                                && (find_answer == null || find_answer == undefined) ){

                                    mark = '';
                                }
                                // OBJECT (object type and length undefined)
                                else
                                if( typeof(find_answer) == 'object'
                                &&  find_answer.length == undefined){

                                    mark = find_answer[iv+1];
                                    if( mark == null){
                                        mark = '';
                                    };

                                    // let arr = [];
                                    // let v = 0;
                                    // for(let key in find_answer){
                                    //     let value = find_answer[key];
                                    //     if( value !== null && value !== undefined){
                                    //         arr.push(value);
                                    //     };
                                    //     v++;
                                    //     if( v === Object.keys(find_answer).length){ //end of loop
                                    //         arr  = arr.join(' || ');
                                    //         mark = `"${arr}"`; // wrapped in double quotes so any commas don't break CSV
                                    //     };
                                    // };
                                }
                                // ARRAY (object type and length NOT undefined)
                                else
                                if( typeof(find_answer) == 'object'
                                &&  find_answer.length != undefined){

                                    if( find_answer.includes(value_choice)
                                    &&  find_answer.length > 0){ // also need to check if array has length. As empty arrays will still pass the conditional .includes(find_answer)

                                        mark = 1;
                                    };
                                };
                            };

                            row.push(mark);

                            if( iv === array_choices.length - 1){ // end of loop

                            };
                        };

                        if( iii === array_groups.length - 1){ // end of loop

                        };
                    };

                    if( ii === array_questions.length - 1){ // end of loop
                        if( is_createHeader[`qs_${id_questionSet}`] === false){
                            is_createHeader[`qs_${id_questionSet}`] = true;
                            this.table_surveyResults[`qs_${id_questionSet}`].push(header);
                        };
                        this.table_surveyResults[`qs_${id_questionSet}`].push(row);
                    };
                };

                if( i === data_questionAnswers.length - 1){ // end of loop

                };
            };



            // LOCAL FUNCTIONS
            function modify_dataQuestions_orderByVersionDescending(data_questions){
                data_questions.sort(function(a, b){
                    return b.version - a.version;
                });
            };
            function modify_questionSets_orderById(questionSets, filter_versions){

                let i = 0;
                for(let key_questionSet in questionSets){
                    /*
                    key_questionSet is qs_1 or qs_2. Use this key to look at filters.
                    If filter has more length more than 1, sort by Id,
                    Else, don't want single results filtered as there is want to view questions in same order as they come.
                    */
                    if( filter_versions[`${key_questionSet}`].length > 1){

                        let array_questions = questionSets[key_questionSet];

                        array_questions.sort(function(a, b){
                            return a.id - b.id;
                        });
                    };

                    i++;
                    if( i === Object.keys(questionSets).length){ //end of loop

                    };
                };
            };
            function remove_versionsNotSelected_fromDataQuestions(data_questions, filter_versions){
                return new Promise((resolve, reject)=>{
                    for(let i = data_questions.length -1; i > -1; i--){
                        let obj_questionSet = data_questions[i];
                        let id_questionSet  = obj_questionSet.questionSetId;
                        let version         = obj_questionSet.version;

                        if( filter_versions[`qs_${id_questionSet}`].includes(version) === false){
                            data_questions.splice(i, 1);
                        };

                        if( i === 0){ // end of loop
                            resolve(data_questions);
                        };
                    };
                });
            };
            function remove_versionsNotSelected_fromQuestionAnswers(data_questionAnswers, filter_versions){
                return new Promise((resolve, reject)=>{
                    for(let i = data_questionAnswers.length -1; i > -1; i--){
                        let obj_questionAnswer = data_questionAnswers[i];
                        let id_questionSet     = obj_questionAnswer.questionSetId;
                        let version            = obj_questionAnswer.version;

                        if( filter_versions[`qs_${id_questionSet}`].includes(version) === false){
                            data_questionAnswers.splice(i, 1);
                        };

                        if( i === 0){ // end of loop
                            resolve(data_questionAnswers);
                        };
                    };
                });
            };
            function retrieve_userDetails(data_users, userId){
                return new Promise((resolve, reject)=>{

                    let userDetails = {

                        name_given: '',
                        name_family: '',
                        email: '',
                        role: []
                    };

                    for(let i = 0; i < data_users.length; i++){
                        let obj_user = data_users[i];
                        let id_user  = obj_user.Username;

                        if( userId == id_user){

                            let attributes = obj_user.Attributes;

                            for(let ii = 0; ii < attributes.length; ii++){
                                let attribute = attributes[ii];

                                if( attribute.Name == 'given_name'){
                                    userDetails.name_given = attribute.Value;
                                }
                                else
                                if( attribute.Name == 'family_name'){
                                    userDetails.name_family = attribute.Value;
                                }
                                else
                                if( attribute.Name == 'email'){
                                    userDetails.email = attribute.Value;
                                }
                                else
                                if( attribute.Name == 'role'){
                                    userDetails.role = attribute.Value.join(' | ');
                                };

                                if( ii === attributes.length - 1){ // end of loop

                                };
                            };
                        };

                        if( i === data_users.length - 1){ // end of loop
                            resolve(userDetails)
                        };
                    };
                });
            };
            function set_questionSets_fromQuestions(questions){

                let seen = {
                    qs_1: [],
                    qs_2: [],
                    qs_3: [],
                    qs_1_versions: [],
                    qs_2_versions: [],
                    qs_3_versions: []
                };

                // LOOP: questions data
                for(let i = 0; i < questions.length; i++){
                    let data_questionSet = questions[i];
                    let id_questionSet   = data_questionSet.questionSetId;
                    let version          = data_questionSet.version;
                    let array_questions  = data_questionSet.data;

                    // LOOP: array_questions
                    for(let ii = 0; ii < array_questions.length; ii++){
                        let obj_question = array_questions[ii];
                        let id_question  = obj_question.id;

                        if( seen[`qs_${id_questionSet}`].includes(id_question) === false){

                            seen[`qs_${id_questionSet}`].push(id_question);
                            seen[`qs_${id_questionSet}_versions`].push(version);

                            questionSets[`qs_${id_questionSet}`].push(obj_question);
                        };

                        if( ii === array_questions.length - 1){ // end of loop

                        };
                    };

                    if( i === questions.length - 1){ // end of loop
                        // console.log(`questionSets`, questionSets);
                    };
                };
            };

        }
    },
    computed: {
        first_visit: function(){
            let output = []
            let submissions = this.submissions
            return output
        },
        second_visit: function(){
            let output = []
            return output
        }
    },
    watch: {
        filter_versions: {
            handler(){
                // EMPTY
                this.table_surveyResults = {
                    qs_1: [],
                    qs_2: []
                }
                // RESET
                this.set_tableSurveyResults();
            },
            deep: true
        }
    }
}
</script>

<style scoped lang='scss'>

    .cont_filter {
        max-width: 1200px;
        padding: 16px 24px;
        margin: 0 auto;

        .wrapper {
            display: flex;

            @media(max-width: 1020px){
                flex-flow: column;
                align-items: center;
            }

            .flex {
                width: 100%;
                max-width: 600px;
                display: flex;
                align-items: center;

                @media(max-width: 575px){
                    flex-flow: column;
                }

                &:nth-of-type(2) {
                    margin-left: 16px;

                    @media(max-width: 1020px){
                        margin-left: initial;
                    }
                }

                .container {
                    padding: 0;

                    > div {
                        > div {
                            padding: 16px 24px 8px;
                        }
                    }
                }

                button {
                    height: auto;
                    margin: 0 16px;
                    padding: 8px 16px;

                    @media(max-width: 575px){
                        margin: -8px 16px 16px;
                    }
                }
            }
        }
    }

</style>
