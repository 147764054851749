<template>
  <div style="text-align: left;">
    <v-list>
      <template v-for="submission in submissions">
        <v-list-item :key="`${parentKey}-submission_${submission.id}`" class="selector" :class="[{'mine':$store.getters.user.username==submission.userId}, {'selected':selection && selection.id==submission.id}]">
          <v-list-item-icon>{{submission.id}}</v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <template v-if="$store.getters.user.username==submission.userId">
                MINE
              </template>
              <template v-else>
                User ID: {{submission.userId}}
              </template>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{new Date(submission.timestamp)}}
              <br>
              Patient ID: {{submission.data.patient.patient_id}}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="select(submission)">
              <v-icon color="grey lighten-1">mdi-briefcase-upload</v-icon>
            </v-btn>
          </v-list-item-action>

        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
    props: {
        parentKey: {
            type: String,
            required: false,
            default: ''
        },
        selection: {
          type: Object,
          required: false
        },
        submissions: {
          type: Array,
          required: true
        }
    },
    methods: {
      select: function(submission){
        this.$emit('select', submission)
      }
    }
}
</script>

<style scoped lang="scss">
.mine{
  font-weight: bold;
}

.selector{
  cursor: pointer;
}
.selector:hover{
  background-color: whitesmoke;
}

.selected{
  color: white;
  font-weight: bold;
  background-color: $primary;
}
</style>